import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(4, 106, 56)',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  '&.incident-name': {
    width: '20%', // Highest priority
  },
  '&.incident-resolution': {
    width: '55%', // High priority
  },
  '&.platform-service': {
    width: '15%', // Medium priority
  },
  '&.status': {
    width: '10%', // Lowest priority
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function MajorIncidentsTables({ currentDate }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  // const fetchMajorAccomp = () => {
  // http://localhost:8000/api/mic-incidents/dates/2024-08-10T14:10:17.870Z/2024-09-26T14:10:17.870Z
  // };

  const fetchMajorIncidents = () => {
    if (startDate && endDate) {
      setLoading(true);
      setIncidents([]);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/mic-incidents/dates/${new Date(startDate).toISOString()}/${new Date(endDate).toISOString()}`,
        )
        .then(res => {
          setIncidents(res.data);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchMajorIncidents();
  }, [startDate, endDate]);

  React.useEffect(() => {
    setStartDate(dayjs(currentDate).subtract(7, 'day').toDate());
    setEndDate(dayjs(currentDate).toDate());
  }, [currentDate]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell className="incident-name">
              Incident Name
            </StyledTableCell>
            <StyledTableCell align="left" className="platform-service">
              Platform Service
            </StyledTableCell>
            <StyledTableCell align="left" className="incident-resolution">
              Resolution
            </StyledTableCell>
            <StyledTableCell align="left" className="status">
              Status
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incidents.map(incident => (
            <StyledTableRow key={incident._id}>
              <StyledTableCell
                component="th"
                scope="row"
                className="incident-name"
              >
                {incident['Incident Name']}
              </StyledTableCell>
              <StyledTableCell align="left" className="platform-service">
                {incident.Platform === 'Not filled in Master Sheet Yet'
                  ? 'Not Found'
                  : incident.Platform}
              </StyledTableCell>
              <StyledTableCell align="left" className="incident-resolution">
                {incident['Incident Resolution']?.replace(/^Resolution\s*/, '')}
              </StyledTableCell>
              <StyledTableCell align="left" className="status">
                {incident.Status}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
