import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// const data = [
//   { name: 'AWS', Requests: 34, Incidents: 35 },
//   { name: 'Azure', Requests: 74, Incidents: 7 },
//   { name: 'GCP', Requests: 21, Incidents: 3 },
//   { name: 'VDI', Requests: 20, Incidents: 19 },
//   { name: 'Others', Requests: 1, Incidents: 3 },
// ];

const MICComposedChart = ({ title, data }) => {
  const result = data?.csp_wise_data.map(e => {
    return { ...e, Incidents: e.incidents, Requests: e.requests };
  });

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // Simulate data fetching
  //   const fetchData = async () => {
  //     // Simulate a delay
  //     await new Promise(resolve => setTimeout(resolve, 2000));
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, []);

  const CustomLabel = ({ x, y, value, stroke }) => {
    return (
      <text
        x={x + 10}
        y={y - 5} // Adjust the y-position to move the label above the line
        fill={stroke}
        fontSize={16}
        textAnchor="middle"
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    ); // Display a loading indicator
  }
  return (
    <div>
      <h5
        style={{
          color: '#046a38',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {title}
      </h5>
      <ResponsiveContainer width="100%" height="92%">
        <ComposedChart
          data={result}
          margin={{
            top: 20,
            right: 20,
            bottom: 0,
            left: 0,
          }}
        >
          {/* <CartesianGrid stroke="#f5f5f5" /> */}
          <XAxis dataKey="name" stroke="#ccc" />
          <YAxis stroke="#ccc" />
          <Tooltip />
          <Legend verticalAlign="bottom" height={36} />

          <Line
            type="linear"
            dataKey="Requests"
            stroke="#757678"
            strokeWidth={3}
            dot={false}
            label={<CustomLabel stroke="#757678" />}
          />
          <Line
            type="linear"
            dataKey="Incidents"
            stroke="#046a38"
            strokeWidth={3}
            dot={false}
            label={<CustomLabel stroke="#757678" />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MICComposedChart;
